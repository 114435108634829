$appbg: #2C3E50;
$line: #6EC6CC;
$placeholder: #817f7f;
$temp: #fff;
$temp-span: #B7B3B3;
$sub: #1ABC9C;
$font: 'Poppins', sans-serif;
html {
    font-size: 16px;
}
body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.App {
    background: $appbg
}

.search-grid {
    // width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 50px;
    margin: 10px 0;
    padding: 10px;
    border-bottom: 1px solid $line;
    position: sticky;
    top: 0;
    z-index: 1;
    background: $appbg;

    input {
        background: none;
        width: 80%;
        border: none;
        padding: 10px;
        color: $placeholder;
        font-size: 1.125rem;
        font-weight: 600;
        font-family: $font;
        &::placeholder {
            color: $placeholder;
            font-size: 1.125rem;
        }
    }
    button {
        background: none;
        border: none;
        &:hover {
            cursor: pointer;
        }
    }
}
.main-weather {
    width: 50%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    // border: 1px solid tomato;
    // height: 100px;
    .temperature {
        font-size: 4rem;
        color: $temp;
        // border: 1px solid tomato;
        margin: 0;
        padding: 0;
        span {
            color: $temp-span
        }
    }
    .temperature-details {
        // border: 1px solid tomato;
        display: grid;
        place-items: center;
    }
}
.weather-description {
    display: grid;
    place-items: center;
    // border: 1px solid tomato;
    p {
        color: $sub;
        padding: 0;
        margin: 0;
        font-family: $font;
    }
}
.current-location {
    display: grid;
    place-items: center;
    h1 {
        color: #fff;
        font-size: 2.25rem;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        span {
            color: #1ABC9C;
        }
    }
}
.weather-sunrise {
    display: flex;
    width: 70%;
    margin: 10px auto;
    // border: 1px solid tomato;
    img, p {
        margin: 0 5px;
    }
    p {
        font-family: $font;
        color: $temp;
        font-size: 0.875rem;
    }
}
.weather-details {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    padding: 30px;
    gap: 10px;
    h3 {
        font-weight: 500;
        color: $temp;
        padding: 0;
        margin: 0;
        font-family: $font;
        font-size: 1.125rem;
    }
    .weather-details-left,
    .weather-details-right {
        width: 100%;
        // height: 200px;
        // background: tomato;
    }
    .weather-details-center {
        background: $sub;
        height: auto;
    }
    ul {
        // border: 1px solid tomato;
        margin: 10px 0;
        padding: 0;
    }
    li {
        list-style: none;
        font-size: 0.875rem;
        color: $temp;
        font-family: $font;
        font-weight: 300;
        display: block;
        line-height: 2.5;
        // border: 1px solid tomato;
        img {
            margin-right: 8px;
            top: 3px;
            position: relative;
        }
    }
}
.daily-forecast-title {
    padding: 30px;
    margin-top: 40px;
    h3 {
        font-weight: 500;
        color: $temp;
        padding: 0;
        margin: 0;
        font-family: $font;
        font-size: 1.125rem;
    }
}
.forecast {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
}
.day {
    // border-right: 1px solid $sub;
    display: grid;
    place-items: center;
    gap: 5px;
    grid-template-columns: repeat(6, 1fr);
    border-bottom: 1px solid $sub;
    &:last-child {
        border: none;
    }
    img {
        width: 64px;
        height: 64px
    }
    .day-details {
        display: flex;
        align-items: center;
        padding: 5px;
        img {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            
        }
    }
    p {
        color: $sub;
        font-size: 0.875rem;
        font-family: $font;
        margin: 0;
        padding: 0;
        margin-left: 5px;
    }
    p.day-info {
        color: $temp;
        font-size: 0.875rem;
        font-family: $font;
        margin: 0;
        padding: 0;
        font-weight: 300;
    }
    h2 {
        margin: 0;
        padding: 0;
        color: #fff;
        font-family: $font;
        font-weight: 600;
        font-size: 1.75rem;
        
    }
}
footer {
    background: $sub;
    div {
        margin: 0 auto;
        // width: 50%;
        padding: 20px;
        // border: 1px solid tomato;
        display: grid;
        place-items: center;
        a {
            font-size: 1.2rem;
            color: #fff;
            font-family: $font;
        }
    }
}


// BREAKPOINTS

@media (min-width: 1024px) {
    .main-weather {
        width: 20%;
        .temperature {
            font-size: 6rem;
            color: $temp;
            // border: 1px solid tomato;
            margin: 0;
            padding: 0;
            span {
                color: $temp-span
            }
        }
        .temperature-details {
            // border: 1px solid tomato;
            display: grid;
            place-items: center;
        }
    }
    .search-grid {
        width: 65%;
        margin: 0 auto;
    }
    .weather-sunrise {
        width: 40%;
        // border: 1px solid tomato;
        display: grid;
        place-items: center;
        grid-template-columns: 80px 1fr 80px 1fr;
        img {
            width: 64px;
            height: 64px;
        }
        p {
            font-size: 1.8rem;
        }

    }
    .weather-details {
        width: 35%;
        margin: 0 auto;
        display: grid;
            place-items: start;
        // .weather-details-left,
        // .weather-details-right {
            
        // }
    }
    .current-location {
        h1 {
            font-size: 4rem;
        }
    }
    .daily-forecast-title {
        width: 65%;
        margin: 0 auto;
    }
    .forecast {
        width: 65%;
        margin: 0 auto;
    }
    
}
@media (min-width: 1440px) {
 
}